<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-button
                @click="cleanUpForm()"
                v-b-modal.form-content-modal
                variant="primary"
                class="btn waves-effect waves-float waves-light btn-primary mb-2"
              >
                Create Content
            </b-button>
            <b-button
                @click="goBack()"
                variant="primary"
                class="btn waves-effect waves-float waves-light btn-primary mb-2 ml-1"
              >
              <!-- <feather-icon icon="ArrowLeftIcon" size="14" />  -->
              Back
            </b-button>
        </div>
        <b-row>
            <b-col cols="6" v-for="(item, index) in content" :key="index">
              <b-card>
                    <b-row>
                        <b-col cols="2" sm="2" md="2" lg="1" align-self="center">
                            <div v-if="item.meta_file.type === 'document'"> 
                                <feather-icon icon="FileTextIcon" size="24" />
                            </div>
                            <div v-else-if="item.meta_file.type === 'video'"> 
                                <feather-icon icon="PlayCircleIcon" size="24" />
                            </div>
                            <div v-else> 
                                <feather-icon icon="ClipboardIcon" size="24" />
                            </div>
                        </b-col>
                        <!-- <b-col cols="6" md="5" lg="6"> -->
                        <b-col cols="5" sm="5" md="5" lg="6">
                            <div class="font-weight-bolder">
                                {{ item.text }}
                            </div>
                            <div class="text-capitalize text-muted">
                                {{ item.meta_file.type }} 
                            </div>
                            <div v-if="item.type == 'post_test' || item.type == 'pre_test'" class="text-capitalize text-muted">
                                {{ item.type === 'post_test' ? 'Post Test' : 'Pre Test' }}
                            </div>
                        </b-col>
                        <!-- <b-col cols="6" sm="6" md="4" lg="3" class="text-center"> -->
                        <b-col cols="5" sm="5" md="5" lg="3" class="text-center" align-self="center">
                            <div v-if="item.meta_file.type === 'document' && item.meta_file.page">
                                <div>
                                    {{ item.meta_file.page }} Pages
                                </div>
                            </div>
                            <div v-else-if="item.meta_file.type === 'video' && item.meta_file.duration">
                                <div>
                                    {{ item.meta_file.duration }} Seconds
                                </div>
                            </div>
                        </b-col>
                        <!-- <b-col cols="12" md="3" lg="3" class="text-right h-50"> -->
                        <b-col cols="12" md="12" lg="2" class="text-right h-50" align-self="center">
                            <b-dropdown size="sm" variant="outline-primary">
                                <template #button-content> 
                                    <feather-icon icon="EditIcon" size="14" /> 
                                </template>
                                <div v-if="item.type !== 'default'">
                                    <b-dropdown-item
                                        @click="goQuestions(item)"
                                    >
                                        <feather-icon icon="ClipboardIcon" class="mr-75" />
                                        Questions
                                    </b-dropdown-item>
                                </div>
                                <div>
                                    <b-dropdown-item
                                        @click="editContent(item)"
                                    >
                                        <feather-icon icon="EditIcon" class="mr-75" />
                                        Update
                                    </b-dropdown-item>
                                </div>
                                <div>
                                    <b-dropdown-item
                                        @click="deleteContent(content.uuid)"
                                    >
                                        <feather-icon icon="TrashIcon" class="mr-75" />
                                        Delete
                                    </b-dropdown-item>
                                </div>
                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <!-- <b-button
                        v-b-modal.form-content-modal
                        variant="primary"
                        class="btn waves-effect waves-float waves-light btn-primary mt-2"
                    >
                      Add Materi
                  </b-button> -->
              </b-card>
          </b-col>
        </b-row>
        <!-- <b-table
            striped
            :items="content"
            :fields="fields"
            :busy="isLoading"
            show-empty
            responsive
        >
            <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>

            <template v-slot:cell(text)="{ item }">
                <span class="text-capitalize">{{ item.text }}</span>
            </template>
            
            <template v-slot:cell(meta_file.type)="{ item }">
                <span v-if="item.type === 'default'" class="text-capitalize">{{ item.meta_file.type }}</span>
                <span v-else class="text-capitalize">{{ item.type === 'pre_test'? 'Pre Test' : 'Post Test' }}</span>
            </template>

            <template v-slot:cell(duration)="{ item }">
                <span class="text-capitalize">{{ item.text }}</span>
            </template>
        </b-table> -->
        <b-modal
            id="form-content-modal"
            centered
            :title="editId != null ? 'Edit E-Learning Content' : 'Add E-Learning Content'"
            size="lg"
            no-close-on-backdrop
            hide-footer
        >
            <ValidationObserver>
                <div class="form">
                    <div class="form-group">
                        <label>Title:</label>
                        <validation-provider
                            name="name"
                            rules="required"
                            v-slot="{ errors, classes }"
                        >
                            <input
                                type="text"
                                class="form-control"
                                v-model="formPayloadContent.text"
                                placeholder="Title"
                                />
                                <small
                                v-for="(validation, index) in validations.text"
                                :key="`errorName${index}`"
                                class="text-danger"
                            >
                                {{ validation }}
                            </small
                            >
                        </validation-provider>
                    </div>
                    <div class="form-group">
                        <label>Type:</label>
                        <validation-provider
                            name="name"
                            rules="required"
                            v-slot="{ errors, classes }"
                        >
                            <v-select
                                id="class_type"
                                label="title"
                                v-model="formPayloadContent.type"
                                :options="optionType"
                                placeholder="-- Pilih --"
                                :reduce="(option) => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small
                                v-for="(validation, index) in validations.type"
                                :key="`errorName${index}`"
                                class="text-danger"
                                >
                                {{ validation }}
                            </small
                            >
                        </validation-provider>
                    </div>
                    <div class="form-group">
                        <label>Order:</label>
                        <validation-provider
                            name="name"
                            rules="required"
                            v-slot="{ errors, classes }"
                        >
                            <input
                                type="number"
                                class="form-control"
                                v-model="formPayloadContent.order"
                                placeholder="Order"
                            />
                            <small
                                v-for="(validation, index) in validations.order"
                                :key="`errorName${index}`"
                                class="text-danger"
                            >
                                {{ validation }}
                            </small
                            >
                        </validation-provider>
                    </div>
                    <div v-if="!is_post_test && !is_pre_test">
                        <div class="form-group">
                            <label>Duration:</label>
                            <validation-provider
                                name="name"
                                rules="required"
                                v-slot="{ errors, classes }"
                            >
                                <input
                                    type="number"
                                    class="form-control"
                                    v-model="formPayloadContent.duration"
                                    placeholder="Duration in seconds"
                                />
                                <small
                                    v-for="(validation, index) in validations.order"
                                    :key="`errorName${index}`"
                                    class="text-danger"
                                >
                                    {{ validation }}
                                </small
                                >
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label>Page:</label>
                            <validation-provider
                                name="name"
                                rules="required"
                                v-slot="{ errors, classes }"
                            >
                                <input
                                    type="number"
                                    class="form-control"
                                    v-model="formPayloadContent.page_count"
                                    placeholder="Page Count"
                                />
                                <small
                                    v-for="(validation, index) in validations.page_count"
                                    :key="`errorName${index}`"
                                    class="text-danger"
                                >
                                    {{ validation }}
                                </small>
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <label for="">Video / PDF:</label><br />
                            <!-- <p class="image__hint"><i>Max 5 mb</i></p> -->
                            <validation-provider name="image" rules="required">
                                <b-form-file
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    @change="changeFile($event)"
                                    />
                                <small v-if="file_url !== null">Current file:</small><br />
                                <video
                                    class="media_file"
                                    controls
                                    v-if="
                                        (file_url !== null && file_type == 'video') ||
                                        file_type == 'mp4' ||
                                        file_type == 'flv' ||
                                        file_type == 'mkv' ||
                                        file_type == '3gp'
                                    "
                                >
                                <source :src="file_url" type="video/mp4" />
                                Your browser does not support the video tag.
                                </video>
                                <!-- <iframe 
                                    v-if="(file_url !== null && file_type == 'pdf')"
                                    id="file"
                                    class="media_file" 
                                    type="application/pdf"
                                    controls 
                                    :src="file_url">
                                </iframe> -->
                                <a :href="file_url" v-if="(file_url !== null && file_type == 'pdf')">
                                    <small>
                                        Link PDF
                                    </small>
                                </a>
                                <!-- <small class="text-danger">{{ validationsCategory }}</small> -->
                            </validation-provider>
                        </div>
                    </div>
                </div>
                <div v-if="isLoading">
                    <br />
                    <b-spinner class="mb-2" variant="primary" /><br />
                </div>
                <b-button
                    v-if="editId == null"
                    variant="primary"
                    @click="createContent()"
                    v-bind:disabled="isLoading"
                >
                    Save E-Learning Subchapter
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    @click="updateContent()"
                    v-bind:disabled="isLoading"
                >
                    Edit E-Learning Subchapter
                </b-button>
            </ValidationObserver>
        </b-modal>  
    </div>
</template>

<script>
import { successNotification, errorNotification } from "@/auth/utils";
import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BSpinner,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BTable,
    VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
import vSelect from "vue-select";
import {
    ValidationProvider,
    ValidationObserver,
    configure,
} from "vee-validate";
import { required } from "@validations";
configure({
    classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
        // ...
    },
});
export default {
    components: {
        //   Table,
        BCard,
        BCol,
        BRow,
        BButton,
        BSpinner,
        BFormInput,
        BFormFile,
        BDropdown,
        BDropdownItem,
        BTable,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    data() {
        return {
            required,
            currentPage: 1,
            isLoading: false,
            result: [],
            content: [],
            link: "",
            isLoadingExport: false,
            formPayloadContent: {
                elearning_uuid: this.$route.params.uuid,
                text: "",
                type: "default",
                file: null,
                order: null,
                duration: null,
                page_count: null
            },
            editId: null,
            createId: null,
            filter: {},
            validations: "",
            optionType: [
                { title: "Default", value: "default" },
                { title: "Pre Test", value: "pre_test" },
                { title: "Post Test", value: "post_test" },
            ],
            file_url: null,
            file_type: "",
            is_default: false,
            is_post_test: false,
            is_pre_test: false,
            fields: [
                { key: "order" },
                { key: "text", label: "Content" },
                { key: "meta_file.type", label: "Type" },
                { key: "meta_file.duration", label: "Duration" },
                { key: "meta_file.page", label: "page" },
            ],
        };
    },
    setup() {
        return {
            successNotification,
            errorNotification,
        };
    },
    created() {
        this.getData();
    },
    watch: {
        'formPayloadContent.type': function(newType) {
          this.is_default = newType === 'default';
          this.is_post_test = newType === 'post_test';
          this.is_pre_test = newType === 'pre_test';
        },
    },
    methods: {
        goBack() {
            this.cleanUpForm();
            this.$router.go(-1);
        },
        cleanUpForm() {
            this.editId = null;
            this.file_url = null;
            this.file_type = null;
            this.formPayloadContent = {
                elearning_uuid: this.$route.params.uuid,
                title: "",
                text: "",
                type: "default",
                order: null,
                duration: null,
                page_count: null,
                file: null,
            };
        },
        getData() {
            this.isLoading = true;
            const elearning_uuid = this.$route.params.uuid;
            this.$http
                .get(`/api/v1/admin/e-learning-content/content?elearning_uuid=${elearning_uuid}`)
                .then((response) => {
                    this.content = response.data.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        changeFile(event) {
            this.formPayloadContent.file = event.target.files[0];
            this.showFile(event.target.files[0]);
        },
        showFile(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
            this.file_url = e.target.result;
            var str = e.target.result;
            var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
            var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
            var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
            this.file_type = imageType;
            };
            reader.readAsDataURL(file);
        },
        editContent(item) {
            this.cleanUpForm();
            this.editId = item.uuid;
            this.formPayloadContent = {
                elearning_uuid: this.$route.params.uuid,
                text: item.text,
                type: item.type,        
                order: item.order,        
                duration: item.meta_file.duration,        
                page_count: item.meta_file.page,        
                file: item.meta_file.file,        
            };
            if(this.formPayloadContent.type === 'default'){
            this.file_url = this.formPayloadContent.file;
            this.file_type = this.file_url.substr(
                this.file_url.lastIndexOf(".") + 1
            );
            this.$bvModal.show("form-content-modal");
            } else {
            this.$bvModal.show("form-content-modal");
            // this.$router.push('/e-learning-content/question/elearning_uuid=' + this.formPayloadContent.elearning_uuid + '&content_uuid=' + this.editId)
            }
        },
        goQuestions(item) {
            this.cleanUpForm();
            this.editId = item.uuid;
            this.formPayloadContent = {
                elearning_uuid: this.$route.params.uuid,
                text: item.text,
                type: item.type,        
                order: item.order,        
                duration: item.meta_file.duration,        
                page_count: item.meta_file.page,        
                file: item.meta_file.file,        
            };
            this.$router.push('/e-learning-content/question/elearning_uuid=' + this.formPayloadContent.elearning_uuid + '&content_uuid=' + this.editId)
        },
        updateContent() {
            this.isLoading = true;
            this.validations = "";
            const payload = new FormData();
            payload.append("elearning_uuid", this.formPayloadContent.elearning_uuid);
            payload.append("text", this.formPayloadContent.text);
            payload.append("type", this.formPayloadContent.type);
            payload.append("file", this.formPayloadContent.file);
            payload.append("order", this.formPayloadContent.order);
            if (this.formPayloadContent.duration !== null ) {
            payload.append("duration", this.formPayloadContent.duration);
            }
            if (this.formPayloadContent.page_count !== null) {
            payload.append("page_count", this.formPayloadContent.page_count);
            }
            this.$http
            .post(`/api/v1/admin/e-learning-content/content/${this.editId}`, payload, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
                this.$bvModal.hide("form-content-modal");
                this.getData();
                successNotification(
                this,
                "Success",
                "E-Learning Subchapter successfully updated!"
                );
                this.cleanUpForm();
                this.isLoading = false;
            })
            .catch((error) => {
                if (error.response.data.meta.validations) {
                this.validations = error.response.data.meta.validations;
                }
                this.isLoading = false;
            });
        },
        createContent() {
            this.isLoading = true;
            this.validations = "";
            const payload = new FormData();
            payload.append("elearning_uuid", this.formPayloadContent.elearning_uuid);
            payload.append("text", this.formPayloadContent.text);
            payload.append("type", this.formPayloadContent.type);
            payload.append("file", this.formPayloadContent.file);
            payload.append("order", this.formPayloadContent.order);
            if (this.formPayloadContent.duration !== null) {
                payload.append("duration", this.formPayloadContent.duration);
            }
            if (this.formPayloadContent.page_count !== null) {
                payload.append("page_count", this.formPayloadContent.page_count);
            }
            this.$http
            .post("/api/v1/admin/e-learning-content/content", payload, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
                this.$bvModal.hide("form-content-modal");
                this.getData(this.currentPage);
                successNotification(
                this,
                "Success",
                "E-Learning Content successfully created"
                );
                this.cleanUpForm();
                this.isLoading = false;

            })
            .catch((error) => {
                if (error.response.data.meta.validations) {
                this.validations = error.response.data.meta.validations;
                }
                this.isLoading = false;
            });
        },
        deleteContent(slug) {
            this.$swal({
                title: "Are you sure?",
                text: "Are you sure to delete this E-Learning Content?",
                icon: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    this.$http
                    .delete(`/api/v1/admin/e-learning-content/content/${slug}`)
                    .then((response) => {
                        this.getData();
                        this.$swal({
                        icon: "success",
                        title: "Success!",
                        text: "E-Learning Content successfully deleted",
                        customClass: {
                            confirmButton: "btn btn-success",
                        },
                        });
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        if (error.response.data.meta.messages.length > 0) {
                        errorNotification(
                            this,
                            "Oops!",
                            error.response.data.meta.messages
                        );
                        }
                    });
                }
            });
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.px-25{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>